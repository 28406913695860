const ACTION_SCOPE = '[Auth]';

export namespace AuthActions {
  export class GetAuthToken {
    static readonly type = `${ACTION_SCOPE} Get Token`;
    constructor(public token: string) {}
  }

  export class SetAuthToken {
    static readonly type = `${ACTION_SCOPE} Set Token`;
    constructor(public token: string) {}
  }

  export class Login {
    static readonly type = `${ACTION_SCOPE} Login`;
    constructor(public payload: { email: string; password: string }) {}
  }

  export class SetLoadingStatus {
    static readonly type = `${ACTION_SCOPE} Set Loading`;
    constructor(public payload: boolean) {}
  }

  export class GetAccountSetupStatus {
    static readonly type = `${ACTION_SCOPE} Get Account Setup Status`;
    constructor(public payload: boolean) {}
  }
}
