import { Injectable, NgZone, OnDestroy, inject } from '@angular/core';
import { AuthService } from '@app/core/services/client/auth.service';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { AuthActions } from './auth.actions';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

export interface AuthStateModel {
  token: string | null;
  id: string | null;
  isAccountSetup: boolean | null;
  loading: boolean | null;
  success: boolean;
  error: boolean | null;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    token: null,
    id: null,
    isAccountSetup: null,
    loading: null,
    success: false,
    error: null,
  },
})
@Injectable()
export class AuthState {
  subs: Subscription[] = [];

  authService = inject(AuthService);
  store = inject(Store);
  router = inject(Router);
  private ngZone = inject(NgZone);

  @Action(AuthActions.Login)
  async login(ctx: StateContext<AuthStateModel>, action: AuthActions.Login) {
    const state = ctx.getState();

    this.store.dispatch(new AuthActions.SetLoadingStatus(true));
    this.authService.login(action.payload).subscribe({
      next: (response) => {
        ctx.setState({
          ...state,
          token: response.token,
          id: response.id,
          isAccountSetup: response.is_account_setup,
          loading: false,
          success: true,
          error: null,
        });

        if (!response.is_account_setup) {
          this.ngZone.run(async () =>
            this.router.navigateByUrl('/create-account/verification')
          );
          return;
        }
        this.ngZone.run(async () =>
          this.router.navigateByUrl('/create-account/type')
        );
      },
      error: () => {
        this.store.dispatch(new AuthActions.SetLoadingStatus(false));
        this.store.reset({ auth: {} });
      },
    });
  }

  @Action(AuthActions.SetLoadingStatus)
  setLoading(
    ctx: StateContext<AuthStateModel>,
    action: AuthActions.SetLoadingStatus
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      loading: action.payload,
    });
  }
}
