import { Selector } from '@ngxs/store';
import { AuthState, AuthStateModel } from './auth.state';

export class AuthSelectors {
  @Selector([AuthState])
  static getAuthToken(state: AuthStateModel): string | null {
    return state.token;
  }

  @Selector([AuthState])
  static getLoadingStatus(state: AuthStateModel): boolean | null {
    return state.loading;
  }

  @Selector([AuthState])
  static isAccountSetup(state: AuthStateModel): boolean | null {
    return state.isAccountSetup;
  }
}
